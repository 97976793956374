import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Strong, LinkBox, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Menu | Neon Nostalgia Bar & Lounge
			</title>
			<meta name={"description"} content={"Sip & Savour the Essence of the '80s, Reimagined"} />
			<meta property={"og:title"} content={"Menu | Neon Nostalgia Bar & Lounge"} />
			<meta property={"og:description"} content={"Sip & Savour the Essence of the '80s, Reimagined"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12:33:06.409Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="80px 0 80px 0"
			lg-padding="60px 0 60px 0"
			md-padding="40px 0 40px 0"
			sm-padding="30px 0 30px 0"
			background="--color-darkL2"
		>
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--light"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Menu
				</Text>
				<Text font="--lead" color="--light" max-width="900px">
					A tantalising sneak peek into our offerings, ensuring every sip and bite is a vibrant trip down memory lane. For a complete experience, step into our lounge or contact us for any specific inquiries.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.1.jpg?v=2023-10-24T12:33:06.459Z"
						border-radius="24px"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						display="block"
						position="static"
						srcSet="https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.1.jpg?v=2023-10-24T12%3A33%3A06.459Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.1.jpg?v=2023-10-24T12%3A33%3A06.459Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.1.jpg?v=2023-10-24T12%3A33%3A06.459Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.1.jpg?v=2023-10-24T12%3A33%3A06.459Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.1.jpg?v=2023-10-24T12%3A33%3A06.459Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.1.jpg?v=2023-10-24T12%3A33%3A06.459Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.1.jpg?v=2023-10-24T12%3A33%3A06.459Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							🍸 Cocktails
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							<Strong>
								- Neon Dreams:
							</Strong>
							{" "}A glowing concoction of tropical flavours with an iridescent glow, taking you straight back to the disco era.
							<br />
							<Strong>
								- Cassette Cosmo:{" "}
							</Strong>
							A fresh twist on the classic Cosmopolitan, garnished with a playful fruit tape reminiscent of the cassette tapes.
							<br />
							<Strong>
								- Pixel Punch:
							</Strong>
							{" "}An electrifying blend of citrus and spirits, with a pixelated sugar rim for the ultimate throwback.
							<br />
							<Strong>
								- VHS Vodka Mix:
							</Strong>
							{" "}A smooth vodka cocktail served in a retro VHS tape case, because why not?
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							🍻 Beers & Brews
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							<Strong>
								Retro Lager:
							</Strong>
							{" "}A crisp, refreshing beer with undertones of the golden age of brewing.
							<br />
							<br />
							<Strong>
								Vinyl Vintage Ale:
							</Strong>
							{" "}A rich ale with notes of caramel, best enjoyed with classic '80s tunes.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.2.jpg?v=2023-10-24T12:33:06.382Z"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.2.jpg?v=2023-10-24T12%3A33%3A06.382Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.2.jpg?v=2023-10-24T12%3A33%3A06.382Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.2.jpg?v=2023-10-24T12%3A33%3A06.382Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.2.jpg?v=2023-10-24T12%3A33%3A06.382Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.2.jpg?v=2023-10-24T12%3A33%3A06.382Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.2.jpg?v=2023-10-24T12%3A33%3A06.382Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_2.2.jpg?v=2023-10-24T12%3A33%3A06.382Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_3.jpg?v=2023-10-24T12:33:06.405Z"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_3.jpg?v=2023-10-24T12%3A33%3A06.405Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_3.jpg?v=2023-10-24T12%3A33%3A06.405Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_3.jpg?v=2023-10-24T12%3A33%3A06.405Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_3.jpg?v=2023-10-24T12%3A33%3A06.405Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_3.jpg?v=2023-10-24T12%3A33%3A06.405Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_3.jpg?v=2023-10-24T12%3A33%3A06.405Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/menu_3.jpg?v=2023-10-24T12%3A33%3A06.405Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							🍔 Bites
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							<Strong>
								Pop Rock Shrimp:
							</Strong>
							{" "}A zesty shrimp dish with a surprising pop rock twist, taking your taste buds on a roller-coaster.
							<br />
							<br />
							<Strong>
								8-bit Bites:
							</Strong>
							{" "}Mini sliders with a pixel-themed presentation, perfectly paired with our cocktails.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							This is just a glimpse of the treasures that await you at Neon Nostalgia Bar & Lounge. For detailed menu options, dietary requirements, or any special needs, kindly reach out to us. We're always happy to cater to our guests' unique preferences and ensure an unforgettable experience.
						</Text>
						<Box
							min-width="100px"
							min-height="100px"
							margin="20px 0px 0px 0px"
							text-align="center"
							padding="20px 0px 0px 0px"
						>
							<Link
								href="/contacts"
								color="#000000"
								padding="8px 24px 8px 024px"
								background="--color-orange"
								text-decoration-line="initial"
								font="--lead"
								margin="0px 0px 0px 0px"
								border-radius="8px"
							>
								Visit Us
							</Link>
						</Box>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12:33:06.409Z"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12%3A33%3A06.409Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12%3A33%3A06.409Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12%3A33%3A06.409Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12%3A33%3A06.409Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12%3A33%3A06.409Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12%3A33%3A06.409Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12%3A33%3A06.409Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</LinkBox>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});